<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <model-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="false"
      @on-submit="createModel"
    >
    </model-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ModelForm from './components/Form'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flatry from '@admin/utils/flatry'
import CouponService from '@admin/services/CouponService'

export default {
  name: 'SendCouponCreate',
  data () {
    return {
      pageTitle: '新发优惠券',
      model: null
    }
  },
  components: {
    ModelForm,
    PlaceholderForm
  },

  async created () {
    const { data } = await flatry(CouponService.sendCreate())
    if (data) {
      let param = {
        coupon_id: '',
        send_name: '',
        // 设定发放时间
        SST: 1, // 发送类型
        SSB: '',
        SSB2: '',
        SSB2S: '',
        SET: '',
        send_sum: '',
        send_user_sum: '',
        send_cycle: 1, // 触发周期
        coupon_start_time_type: 1, // 优惠券生效日期类型
        coupon_start_time_body: '',
        coupon_start_time_body_two_2: '',
        coupon_end_time_type: 1,
        coupon_end_time_body: '',
        // 设定消息通知
        send_type: [], // 发送类型
        param: {
          accord: []
        },
        send_where: []// 查询的发送条件
      }
      param.coupons = data.data.coupons
      param.where = data.data.where
      param.members = data.data.member
      param.shops = data.data.shopTemp
      param.classify = data.data.classify
      param.tempList = data.data.tempList
      param.smsList = data.data.smsList
      this.model = param
    }
  },
  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(CouponService.sendCreate(model))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/send-coupon/update',
          query: { id: data.data }
        })
        success()
      }

      callback()
    }
  }
}
</script>
<style lang='' scoped>
</style>
